import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class BlockCustomerSocialWall {
    constructor() {
        if ($('.instagramList').length > 0) {
            BlockCustomerSocialWall.buildSlider()
        }
    }

    static async buildSlider() {
        const swiperKey = '.instagramList'
        const slideItem = '.instagramItem'

        const options = {
            mousewheel: {
                forceToAxis: true,
            },
            slidesPerView: 2,
            spaceBetween: 16,
            speed: 1000,
            navigation: false,
            keyboard: true,
            loop: false,
            freeMode: {
                enabled: true,
            },
            freeModeFluid: true,
            passiveListeners: false,
            watchSlidesVisibility: true,
            breakpoints: {
                1024: {
                    slidesPerView: 4,
                },
            },
        }

        $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')
        const mySwiper = await Swiper.create(swiperKey, options)
    }

    static initInstagramWall() {
        const $selector = $("[data-element='social-wall']")

        /* Strate social wall --------------------- */
        if ($selector.length > 0 && !$selector.is(':hidden')) {
            apiFetch({
                path: IRISCollectionCustomer.irisSsoInstagramPath,
                method: 'POST',
                data: { limit: 4 },
            })
                .then((result) => {
                    $selector.html(result.render)
                    import(
                        '@iris.interactive/handcook/public/scripts/components/lazyload/lazyload.component' /* webpackChunkName: "scripts/hc_lazyload" */
                    ).then(({ HcLazyload }) => {
                        HcLazyload.update()
                    })
                })
                .catch(() => {
                    $selector.html(IRISCollectionCustomer.irisSsoInstagramError)
                })
        }
    }
}

new BlockCustomerSocialWall()
